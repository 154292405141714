/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Media from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import DotWhack from '../DotWhack';
import Copy from '../Copy';
import { handleOnClick } from '../utils/pathUtils';
import convertAlphaDecToHex from '../../../helpers/contentstack/convertAlphaDecToHex';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useSSRMediaQuery from '../../../helpers/hooks/useSSRMediaQuery';
import getBackgroundColor from '../../../helpers/getBackgroundColor';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../helpers/noop';

const getMsgHeight = (genMsgStyles, screenSize) => {
    const borderSettings = genMsgStyles?.filter((style) => Object.keys(style)[0] === 'border')[0]?.border;
    const circleSettings = borderSettings?.circular_panel;
    if (circleSettings) {
        if (screenSize === 'desktop') return `${circleSettings.circle_height_desktop}px`;
        if (screenSize === 'mobile') return `${circleSettings.circle_height_mobile}px`;
    }
    return 'auto';
};

const getMsgWidth = (genMsgStyles, screenSize) => {
    const widths = genMsgStyles?.filter((style) => Object.keys(style)[0] === 'width')[0]?.width;
    const borderSettings = genMsgStyles?.filter((style) => Object.keys(style)[0] === 'border')[0]?.border;
    const circleSettings = borderSettings?.circular_panel;

    if (!widths) return null;
    const unit = widths.unit === 'Percentage' ? '%' : 'px';

    // for tablet width, split the difference.
    // const tabletWidth = widths.max_width_mobile + (widths.max_width_desktop - widths.max_width_mobile) / 2;
    let width = '35%';

    switch (screenSize) {
        case 'desktop':
            width = circleSettings?.make_circle ? `${circleSettings?.circle_height_desktop}px` : `${widths.max_width_desktop}${unit}`;
            break;
        case 'tablet':
            width = circleSettings?.make_circle ? `${circleSettings?.circle_height_desktop}px` : `${widths.max_width_desktop}${unit}`;
            break;
        case 'mobile':
            width = circleSettings?.make_circle ? `${circleSettings?.circle_height_mobile}px` : `${widths.max_width_mobile}${unit}`;
            break;
        default:
            return '35%';
    }
    return width;
};

// eslint-disable-next-line no-unused-vars
const calcTabletValue = (screenWidth, mbValue, dtValue, mbBreakpoint = 600, dtBreakpoint = 1100) => {
    /**
     *    @desc Calculate the interpolated value of something at a screenWidth in the gap between breakpoints.

     *    Typically in the cms we have fields for desktop and mobile but not for screenwidths in between
     *    this function should be fleixible enough to pass those values in and get an interpolated value for
     *    screensizes in between.  I originally wrote it for heights, but it was supeflous. Hoping it will
     *    come in handly with other properties.

     *                             (return)
     *        mbValue = 100    interpVal = 300    dtValue = 500
     *        mbBreakpoint       screenWidth       dtBreakpoint
     *            \/                 \/                 \/
     *    --------()-----------------()-----------------()------- (line repesenting screenWidths)
    */

    if (screenWidth >= dtBreakpoint) return dtValue;
    if (screenWidth <= mbBreakpoint) return mbValue;

    const gap = dtValue - mbValue;
    const ratio = ((screenWidth - mbBreakpoint) / (dtBreakpoint - mbBreakpoint));
    const interpolatedValue = mbValue + ratio * gap;
    return interpolatedValue;
};

const parseStyleDataToJss = (styleData) => {
    // the style data is created by the content creator in the general message styling section of the Hero Single Responsive content type
    const jss = {};
    if (styleData && Array.isArray(styleData)) {
        styleData.forEach((styleObj) => {
            const styleKey = Object.keys(styleObj)[0];
            // eslint-disable-next-line default-case
            switch (styleKey) {
                case 'message_alignment':
                    jss.textAlign = styleObj[styleKey].text_align;
                    break;
                case 'margin':
                    jss.marginTop = styleObj[styleKey]?.top;
                    jss.marginBottom = styleObj[styleKey]?.bottom;
                    jss.marginLeft = styleObj[styleKey]?.left;
                    jss.marginRight = styleObj[styleKey]?.right;
                    break;
                case 'padding':
                    jss.paddingTop = styleObj[styleKey]?.top;
                    jss.paddingRight = styleObj[styleKey]?.right;
                    jss.paddingBottom = styleObj[styleKey]?.bottom;
                    jss.paddingLeft = styleObj[styleKey]?.left;
                    break;
                case 'bg_color':
                    if (styleObj[styleKey]?.background_opacity) {
                        jss.backgroundColor = convertAlphaDecToHex(styleObj[styleKey]?.color.color, styleObj[styleKey]?.background_opacity);
                    } else {
                        jss.backgroundColor = styleObj[styleKey]?.color.color;
                    }
                    break;
                case 'border':
                    jss.border = `${`${styleObj[styleKey]?.width}px`} ${getBackgroundColor(styleObj[styleKey]?.color)} ${styleObj[styleKey]?.style}`;
                    jss.borderRadius = `${styleObj[styleKey]?.unit?.toLowerCase() === 'pixel' ? `${styleObj[styleKey]?.border_radius}px` : `${styleObj[styleKey]?.border_radius}%`}`;
                    break;
            }
        });
        return jss;
    }
    return null;
};

const useStyles = makeStyles((theme) => ({
    topContainer: ({ removeBottomMargin, displayFullWidth }) => ({
        maxWidth: displayFullWidth ? '100%' : '1440px',
        margin: removeBottomMargin ? '0 auto' : '2rem auto',
    }),
    topContainerWithGrid: ({ displayFullWidth }) => ({
        maxWidth: displayFullWidth ? '100%' : '1440px',
        margin: '0 auto',
        width: '100%',
    }),
    link: {
        textDecoration: 'none',
        color: '#2f2f2f',
    },
    heroContainer: ({
        justifyMsgContent, bgImgPosition, minHeight, mobileMinHeight, imageUrl, makeCircle, displayFullWidth,
    }) => ({
        // images should have empty space for message panel to float over
        display: 'flex',
        maxWidth: displayFullWidth ? '100%' : '1400px',
        margin: '0 auto',
        justifyContent: `${justifyMsgContent}`,
        alignItems: makeCircle ? 'center' : 'inherit',
        backgroundImage: `url(${imageUrl}?auto=webp), linear-gradient(270deg, #E7EDF1, #F2F8FC, #E7EDF1)`,
        backgroundSize: 'cover',
        backgroundPositionX: bgImgPosition,
        [theme.breakpoints.up(1200)]: {
            minHeight: minHeight ? `${minHeight}px` : '600px',
        },
        [theme.breakpoints.down(1200)]: {
            minHeight: (mobileMinHeight + minHeight) / 2,
        },
        [theme.breakpoints.down(600)]: {
            minHeight: mobileMinHeight,
        },
    }),
    heroContainerWithGrid: ({
        justifyMsgContent, bgImgPosition, minHeight, mobileMinHeight, imageUrl, makeCircle, displayFullWidth,
    }) => ({
        // images should have empty space for message panel to float over
        display: 'flex',
        maxWidth: displayFullWidth ? '100%' : '1400px',
        margin: '0 auto',
        justifyContent: `${justifyMsgContent}`,
        alignItems: makeCircle ? 'center' : 'inherit',
        backgroundImage: `url(${imageUrl}?auto=webp), linear-gradient(270deg, #E7EDF1, #F2F8FC, #E7EDF1)`,
        backgroundSize: 'cover',
        backgroundPositionX: bgImgPosition,
        [theme.breakpoints.up(1200)]: {
            minHeight: minHeight ? `${minHeight}px` : '600px',
        },
        [theme.breakpoints.down(1200)]: {
            minHeight: (mobileMinHeight + minHeight) / 2,
        },
        [theme.breakpoints.down(600)]: {
            minHeight: mobileMinHeight,
            width: '100%',
        },
    }),
    messageContainer: ({ getMessageColor, genMsgStylesData }) => ({
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down(900)]: {
            padding: `${calcTabletValue(900, 5, 30)}px`,
        },
        height: getMsgHeight(genMsgStylesData, 'desktop') || 'auto',
        // cta
        '& button': {
            alignSelf: 'center',
            [theme.breakpoints.down(890)]: {
                fontSize: 'clamp(1.6em, 1.7vw, 1em) !important',
            },
        },
        [theme.breakpoints.down(600)]: {
            padding: 'clamp(5px, 1.5vw, 30px) !important',
            height: getMsgHeight(genMsgStylesData, 'mobile') || 'auto',
        },
        '& *': {
            opacity: '1',
            color: `${getBackgroundColor(getMessageColor?.color?.message_copy_color)}`,
        },
    }),
    generalMessageStylesParsed: ({ genMsgStylesData }) => parseStyleDataToJss(genMsgStylesData),
    copyStylesParsed: ({ item }) => parseStyleDataToJss(item),
    messageWidth: ({ genMsgStylesData }) => ({
        maxWidth: genMsgStylesData?.width?.max_width_desktop,
        [theme.breakpoints.up(1200)]: {
            width: getMsgWidth(genMsgStylesData, 'desktop') || '30%',
        },
        [theme.breakpoints.down(1200)]: {
            width: getMsgWidth(genMsgStylesData, 'tablet') || '45%',
        },
        [theme.breakpoints.down(600)]: {
            width: getMsgWidth(genMsgStylesData, 'mobile') || '60%',
        },
    }),
    messageContainerMobile: {
        padding: 'clamp(5px, 1.5vw, 30px)',
    },
    lcpImage: {
        display: 'none',
        // does not display but is fetched earlier in the page process/render, improving LCP
    },
}));

const HeroSingleResponsive = ({ data, salesforceResponse, setShowSkeleton }) => {
    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    if (data && (data.message_collection || (data.desktop_bg_image?.url || data.mobile_bg_image?.url))) {
        const genMsgStylesData = data.general_message_styling || [];
        const justifyMsgContent = data.justify_message_content;
        const bgImgPosition = data.background_image_positioning;
        const minHeight = data.min_height;
        const mobileMinHeight = data.mobile_min_height;
        const makeCircle = genMsgStylesData.filter((s) => Object.keys(s)[0] === 'border')[0]?.border?.circular_panel?.make_circle;
        const msgHeightDt = null;
        const msgHeightMb = null;
        const device = useSSRMediaQuery();
        const history = useHistory();
        const imageUrl = device === 'mobile' ? data.mobile_bg_image?.url : data.desktop_bg_image?.url;
        const imgAlt = device === 'mobile' ? data.mobile_bg_image?.description : data.desktop_bg_image?.description;
        const [getMessageColor] = genMsgStylesData?.filter((value) => value?.color);
        const removeBottomMargin = data?.remove_bottom_margin;
        const displayFullWidth = data?.display_full_width;
        const dynamicId = `hero-single-responsive-${data?.uid}`;
        const classes = useStyles({
            genMsgStylesData,
            justifyMsgContent,
            bgImgPosition,
            minHeight,
            mobileMinHeight,
            msgHeightDt,
            msgHeightMb,
            imageUrl,
            getMessageColor,
            makeCircle,
            removeBottomMargin,
            displayFullWidth,
        });

        const dispatch = useDispatch();
        const ffIsHeroTestEnabled = useSelector(getFeatureFlag('is-hero-test-enabled'));
        const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

        if (ffIsHeroTestEnabled) {
            dispatch(trackEvent({
                eventCategory: data?.a_b_testing?.tracking_event_category || 'eventCategory NOT SET IN CMS',
                eventAction: data?.a_b_testing?.tracking_event_action || 'eventAction NOT SET IN CMS',
                eventLabel: data?.a_b_testing?.tracking_event_label || 'eventLabel NOT SET IN CMS',
                nonInteraction: true,
                queued: true,
            }));
        }

        const overlays = data.overlays || [];
        const OverlaysMarkup = () => (
            // TODO: make dot whack position match the width of the panel if you want it on the border of the message panel
            overlays && overlays?.length ? (
                overlays.map((overlay) => (
                    overlay.whack && <DotWhack data={overlay.whack} />
                ))
            ) : null
        );

        // set default line height of copy blocks to 1em if undefined
        // export this "defaultLineHeightTo1" fn to some helper folder.
        // or maybe put it in a super function called usePrettyDefaults() that would also set font-fam, font-size, color
        const messageBlocks = JSON.parse(JSON.stringify(data.message_collection));
        if (messageBlocks) {
            messageBlocks.forEach((msg) => {
                if (Object.keys(msg)[0] === 'copy') {
                    const msgProps = msg.copy;
                    if (msgProps && msgProps.styles.every((style) => Object.keys(style)[0] !== 'line_height')) {
                        msgProps.styles.push({ line_height: { unit: 'em', value: 1 } });
                    }
                }
            });
        }

        const mobileMsgCollection = messageBlocks?.filter((msg) => {
            const key = Object.keys(msg)[0] || null;
            const viewport = msg[key].viewport;
            return viewport === 'Mobile' || viewport === 'Both' || !viewport;
        });

        const desktopMsgCollection = messageBlocks?.filter((msg) => {
            const key = Object.keys(msg)[0] || null;
            const viewport = msg[key].viewport;
            return viewport === 'Desktop' || viewport === 'Both' || !viewport;
        });

        const MobileHeroSingle = () => (
            <div className={ffHasHomepageGridStructure ? classes.heroContainerWithGrid : classes.heroContainer} data-testid="hero-single-mobile">
                <div className={`${classes.generalMessageStylesParsed} ${classes.messageContainer} ${classes.messageWidth} ${classes.messageContainerMobile}`}>
                    <OverlaysMarkup />
                    <Copy data={mobileMsgCollection} />
                    {/* prefetch image used as BG to improve LCP */}
                    <img src={`${imageUrl}?auto=webp`} alt={imgAlt || 'background image'} className={classes.lcpImage} width="0" height="0" />
                </div>
            </div>
        );

        const DesktopHeroSingle = () => (
            <div className={ffHasHomepageGridStructure ? classes.heroContainerWithGrid : classes.heroContainer} data-testid="hero-single-desktop">
                <div className={`${classes.generalMessageStylesParsed} ${classes.messageContainer} ${classes.messageWidth}`}>
                    <OverlaysMarkup />
                    <div>
                        <Copy data={desktopMsgCollection} />
                    </div>
                </div>
                {/* prefetch image used as BG to improve LCP */}
                <img src={`${imageUrl}?auto=webp`} alt={imgAlt || 'background image'} className={classes.lcpImage} width="0" height="0" />
            </div>
        );

        // render the layout based on media query
        const renderBasedOnMediaQuery = () => (
            <Media query={{ maxWidth: 600 }} defaultMatches={device === 'mobile'}>
                {(matches) => (matches ? MobileHeroSingle() : DesktopHeroSingle())}
            </Media>
        );

        const trackClickThrough = useClickStreamCustomEventsTracking({
            salesforceResponse,
            isFireImpression: !!salesforceResponse?.content,
            page: { type: 'home' },
        });

        return (
            <div id={dynamicId} className={ffHasHomepageGridStructure ? classes.topContainerWithGrid : classes.topContainer}>
                {
                    data?.banner_disable_linking
                        ? (
                            renderBasedOnMediaQuery()
                        ) : (
                            <Link
                                to={data.link?.link?.href}
                                title={data.link?.link?.title}
                                data-ga-category={data.link?.tracking_event_category || ''}
                                data-ga-action={data.link?.tracking_event_action || ''}
                                data-ga-label={data.link?.tracking_event_label || ''}
                                className={classes.link}
                                data-testid="hero-single-responsive"
                                onClick={(e) => {
                                    dispatch(trackEvent({
                                        eventCategory: data.link?.tracking_event_category || '',
                                        eventAction: data.link?.tracking_event_action || '',
                                        eventLabel: data.link?.tracking_event_label || '',
                                    }));
                                    trackClickThrough();
                                    handleOnClick(e, data.link?.link?.href, history);
                                }}
                            >
                                {renderBasedOnMediaQuery()}
                            </Link>
                        )
                }
            </div>
        );
    }
    return <></>;
};

HeroSingleResponsive.propTypes = {
    data: array.isRequired,
    salesforceResponse: object,
    setShowSkeleton: func,
};

HeroSingleResponsive.defaultProps = {
    salesforceResponse: {},
    setShowSkeleton: noop,
};

export default HeroSingleResponsive;
